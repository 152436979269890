<template>
<admin-ui>

  <template v-slot:title>
   Dashboard
  </template>

        <!-- This example requires Tailwind CSS v2.0+ -->
<div class="flex flex-col">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

      <!--<div v-if="forms.data && forms.data.length == 0">
        <p class="mb-5 text-gray-500">You have no forms set up yet. Create your first form by hitting the button below.</p>

        <router-link :to="{name:'NewForm'}" class="mb-5 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Create form</router-link>

      </div>-->

      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Form title
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Submissions
              </th>

              <th scope="col" class="relative px-6 py-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="form in forms.data" :key="form.id">
              <td class="px-6 py-4 whitespace-nowrap">
                 <div class="text-sm text-gray-900">{{form.title}}</div>
                <div class="text-sm text-gray-500">To: {{form.send_to}}</div>
                   <div class="text-sm text-gray-500">{{url}}/f/{{form.unique_id}}</div>
              </td>
         
              <td class="px-6 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {{form.submissions_count}}
                </span>
              </td>

              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <router-link :to="{name:'Form',params: {id:form.id}}" class="text-indigo-600 hover:text-indigo-900">Edit</router-link>
              </td>
            </tr>

            <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<form-pagination :data="forms" @pagination-change-page="getForms"/>



</admin-ui>
</template>

<script>
import FormPagination from '@/components/form-pagination'
import adminUi from '@/components/admin-ui'
export default {
    components: {adminUi, FormPagination},
    created(){
      this.getForms();
    },
    data(){
      return {
        forms: false
      }
    },
    computed:{
      url(){
        return process.env.VUE_APP_URL
      }
    },
    methods:{
      getForms(page = 0){
        this.$axios.get('/form?page='+page).then((response)=>{
            this.forms = response.data;
        });
      }
    }
}
</script>
