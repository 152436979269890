<template>
  <div class="mt-5" v-if="data.last_page !== 1">

    <nav class="bg-white py-3 flex items-center justify-between border-t border-gray-200" aria-label="Pagination">
  <div class="hidden sm:block">
    <p class="text-sm text-brand-blue-500 font-display">
      Showing
      <span class="font-medium">{{data.from}}</span>
      to
      <span class="font-medium">{{data.to}}</span>
      of
      <span class="font-medium">{{data.total}}</span>
      results
    </p>
  </div>
  <div class="flex-1 flex justify-between sm:justify-end">
    <button  type="button" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mr-2" :disabled="data.current_page <= 1" @click.prevent="prev">
      Previous
    </button>
    <button  :disabled="data.current_page >= data.last_page" type="button" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" @click.prevent="next">
      Next
    </button>
  </div>
</nav>

  </div>
</template>

<script>
export default {
  name: '',
  props: {
    data: {
      required: true
    }
  },
  data(){
    return {
      page: 1
    }
  },
  methods: {
    next(){
      this.page++;
      this.$emit('pagination-change-page',this.page);
      window.scrollTo(0,0);
    },
    prev(){
      this.page--;
      this.$emit('pagination-change-page',this.page);
      window.scrollTo(0,0);
    },
    changePage(page){
      this.page = page;
      this.send();
    },
    send(){
        this.$emit('pagination-change-page',this.page);
    }
  }
}
</script>

<style lang="less" scoped>
button{
  &.active{
    background:#2c5281;
    color:white;
  }
  &:focus{
    outline:0;
  }
  &:disabled{
    background:none;
    color:silver;
    cursor:not-allowed;
  }
}
</style>
